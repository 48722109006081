import { MonitorStatusType } from "../Consts";

export class Country {
  id!: number;
  name!: string;
  code!: string;
  continent!: string;
  capital!: string;
  currencyId!: number;
  phoneCode!: string;
}
export class Currency {
  id!: number;
  name!: string;
  code!: string;
  symbol!: string;
  format!: string;
  fractionSize!: number;
}
export class InitializeTeamReq {
  name!: string;
  slug!: string;
  groupName!: string;
}
export class Interval {
  id!: number;
  seconds!: number;
  monthlyPrice!: number;
}
export class Product {
	id!: number;
	productType!: string;
	monitorInterval!: Interval;
	name!: string;
	unitType!: string;
	sort!: number;
	createdAt!: string;
	discontinuedAt!: string;
  prices!: ProductPrice[];
  phoneAlertCredits!: number;
}
export class ProductPrice {
  id!: number;
	name!: string;
	threshold!: number;
	unitPrice!: number;
	monthlyPrice!: number;
	sort!: number;
	createdAt!: string;
}
export class Invitation {
	uuid!: string;
	teamName!: string;
	createdAt!: string;
}
export class Locale {
  id!: number;
  name!: string;
  code!: string;
}
export class Member {
  uuid!: string;
  email!: string;
  firstName!: string;
  lastName!: string;
  role!: string;
  avatarUrl!: string;
  createdAt!: string;
  invited!: boolean;
}

export class CreateApiTokenReq {
	name!: string
  allowWrite!: boolean
}

export class UpdateApiTokenReq {
	name!: string
  allowWrite!: boolean
}

export class ApiToken {
  uuid!: string
	name!: string
  allowWrite!: boolean
  token!: string
  createdAt!: string;
}

export class MonitorEventDetailRegion {
	regionId!: number
	status!: MonitorStatusType
	responseCode!: number
	message?: string
}

export class MonitorEventDetail {
	message?: string
	detailRegions!: MonitorEventDetailRegion[]
}

export class MonitorHttpHeader {
  name!: string;
  value!: string;
}

export class MonitorHttpRequest {
  method!: string;
  headers!: MonitorHttpHeader[];
  body!: string;
}

export class MonitorHttpResponse {
  status!: string[];
  statusMatches!: boolean;
  keywordHeader!: string;
  keywordHeaderExists!: boolean;
  keywordBody!: string;
  keywordBodyExists!: boolean;
}

export class MonitorTcpOptions {
  keywordResponse!: string;
  keywordResponseExists!: boolean;
  dataRequest!: string;
}

export class MonitorMaintenanceTime {
	startHour!: number
	startMinute!: number
	stopHour!: number
	stopMinute!: number
}

export class MonitorMaintenance {
	days!: number[]
	times!: MonitorMaintenanceTime[]
	timeZone!: string
}

export class Monitor {
  uuid!: string;
  groups!: Group[];
  alerts!: Alert[];
  type!: string;
  urn!: string;
  intervalId!: number;
  interval!: Interval;
  name!: string;
  title!: string;
  description!: string;
  regions!: Region[];
  status!: MonitorStatusType;
  statusSince!: string;
  degradedThreshold!: number;
  apdexThreshold!: number;
  updatedAt!: string;
  rtUpdatedAt!: string;
  rt!: number[];
  urlAt!: string;
  urlStatusPending!: boolean
  timeout!: number;
  tcpOptions?: MonitorTcpOptions
  httpFollowRedirects!: boolean;
  httpRequest?: MonitorHttpRequest
  httpResponse?: MonitorHttpResponse
  maintenance?: MonitorMaintenance
}

export class CreateMonitorReq {
  type!: string;
  urn!: string;
  intervalId!: number;
  groups!: string[];
  alerts!: string[];
  regions!: number[];
  name!: string;
  title!: string;
  description!: string;
  degradedThreshold!: number;
  apdexThreshold!: number;
  noRedirectToCheckout!: boolean;
  httpFollowRedirects!: boolean;
  timeout!: number;
  tcpOptions?: MonitorTcpOptions
  httpRequest?: MonitorHttpRequest
  httpResponse?: MonitorHttpResponse
  maintenance?: MonitorMaintenance
}

export class UpdateMonitorReq {
  type!: string;
  urn!: string;
  intervalId!: number;
  groups!: string[];
  alerts!: string[];
  regions!: number[];
  name!: string;
  title!: string;
  description!: string;
  degradedThreshold!: number;
  apdexThreshold!: number;
  httpFollowRedirects!: boolean;
  timeout!: number;
  tcpOptions?: MonitorTcpOptions
  httpRequest?: MonitorHttpRequest
  httpResponse?: MonitorHttpResponse
  maintenance?: MonitorMaintenance
}

export class MonitorServerEvent {
  monitor!: Monitor;
  regionId?: number;
  regionTotalRt?: number;
  responseCode?: number;
  eventDetail?: MonitorEventDetail;
}

export class MemberRoleServerEvent {
  userUuid!: string;
	userEmail!: string;
	role!: string;
}

export class MonitorRegion {
	regionId!: number;
	status!: MonitorStatusType;
  statusSince!: string;
  updatedAt!: string;
  rt!: number[];
  totalRt?: number;
  responseCode!: number;
}

export class MonitorRegionAverageRtByDay {
  regionId!: number;
  rt!: number[];
}

export class AverageRt {
  apdexSamples!: number;
  apdexSatisfied!: number;
  apdexThreshold!: number;
  apdexTolerated!: number;
  rt!: number[];
  createdAt!: string;
}

export class MonitorEvent {
  uuid!: string;
  monitorUuid!: string;
  status!: MonitorStatusType;
  createdAt!: string;
  monitor!: Monitor;
  detail?: MonitorEventDetail;
}

export class MonitorRegionEvent {
  uuid!: string;
  monitorRegionId!: number;
  status!: MonitorStatusType;
  responseCode!: number;
  message?: string;
  createdAt!: string;
}
export class MonitorStatusCount {
	status!: MonitorStatusType;
	count!: number;
}
export class MonitorEventStatusByDate {
  date!: string;
  status!: MonitorStatusType[];
}
export class MonitorRegionEventStatusByDate {
  date!: string;
  status!: MonitorStatusType[];
}
export class Page {
  uuid!: string;
  name!: string;
  title!: string;
  description!: string;
  logoUrl!: string;
  faviconUrl!: string;
  slug!: string;
  customDomain!: string;
  googleAnalyticsId!: string;
  useGroups!: boolean;
  statuses!: PageStatus[];
}
export class PageStatus {
	uuid!: string;
	name!: string;
	title!: string;
	type!: string;
	status!: MonitorStatusType;
  monitors!: PageStatusMonitor[];
}
export class PageStatusMonitor {
	uuid!: string;
	status!: MonitorStatusType;
}
export class CreatePageReq {
  name!: string;
  title!: string;
  description!: string;
  slug!: string;
  customDomain!: string;
  googleAnalyticsId!: string;
  useGroups!: boolean;
  statuses!: string[];
}
export class UpdatePageReq {
  name!: string;
  title!: string;
  description!: string;
  slug!: string;
  customDomain!: string;
  googleAnalyticsId!: string;
  useGroups!: boolean;
  statuses!: string[];
  removeLogo!: boolean;
  removeFavicon!: boolean;
}
export class UpdateSessionUserReq {
  firstName!: string;
  lastName!: string;
  timeZoneId!: number;
  countryId!: number;
}
export class ChangeSessionUserPasswordReq {
  currentPassword!: string;
  password!: string;
  passwordConfirmation!: string;
}
export class ChangeSessionUserEmailReq {
  email!: string;
  password!: string;
}
export class Group {
  uuid!: string;
  name!: string;
  title!: string;
  description!: string;
  color!: string;
  monitors!: Monitor[];
}
export class CreateGroupReq {
  name!: string;
  title!: string;
  description!: string;
  color!: string;
  monitors!: string[];
  noRedirectToCheckout!: boolean;
}
export class UpdateGroupReq {
  name!: string;
  title!: string;
  description!: string;
  color!: string;
  monitors!: string[];
}
export class GetBootstrapRes {
  user!: User;
  products!: Product[];
  regions!: Region[];
  timeZones!: TimeZone[];
  teams!: Team[];
  teamsPaginator!: Paginator;
  invitations!: Invitation[];
  countries!: Country[];
  monitorTypes!: string[];
  productsNonFreeEnabled!: boolean;
}
export class GetTeamRes {
  team!: Team;
}
export class Region {
  id!: number;
  countryId!: number;
  country!: Country;
  name!: string;
  systemName!: string;
}
export class Session {
  id!: number;
  token!: string;
  userId!: number;
}
export class Team {
  uuid!: string;
  name!: string;
  slug!: string;
  groups!: Group[];
  displayStatusImage!: boolean;
  hmacSecretKeyCreatedAt!: string;
  isWorkspace!: boolean;
  balance!: number;
  balanceFrom!: string;
  balanceTo!: string;
  phoneAlertCredits!: number;
  createdAt!: string;
  updatedAt!: string;
}
export class CreateTeamReq {
  name!: string;
  slug!: string;
  isWorkspace!: boolean;
}
export class UpdateTeamReq {
  name!: string;
  slug!: string;
  groupUuid!: string;
}
export class UpdateTeamNameReq {
  name!: string;
}
export class UpdateTeamDisplayStatusImageReq {
  displayStatusImage!: boolean;
}
export class UpdateTeamSlugReq {
  slug!: string;
}
export class Time {
}
export class TimeZone {
  id!: number;
  name!: string;
  systemName!: string;
  countryCode!: string;
  utc!: string;
}
export class User {
  uuid!: string;
  email!: string;
  firstName!: string;
  lastName!: string;
  avatarUrl!: string;
  timeZoneId!: number;
  countryId!: number;
  createdAt!: string;
  updatedAt!: string;
  newEmail?: string;
  registeredWithEmail!: boolean;
  requiresConfirmation!: boolean;
}
export class ValidatePageSlugReq {
  slug!: string;
}
export class ValidatePageCustomDomainReq {
  customDomain!: string;
}
export class ValidateTeamSlugReq {
  slug!: string;
}

export class ValidateMonitorUrnReq {
  urn!: string;
  type!: string;
}

export class InviteMembersReq {
  teamUuid!: string;
  emails!: string[];
}
export class Paginator {
  recordOffset!: number;
  pageSize!: number;
  pagesCount!: number;
  recordsCount!: number;
  recordOffsetChanged!: boolean;
}
export class GetMonitorEventsRequest {
  recordOffset!: number;
  pageSize!: number;
}
export class GetTeamsRequest {
  recordOffset!: number;
  pageSize!: number;
}

export class CreateAccountRequest {
  email!: string
  password!: string
  passwordConfirmation!: string
  invitationUuid!: string
  acceptTermsOfService!: boolean
  reCaptchaToken!: string
	reCaptchaV2!: boolean
}

export class SendResetPasswordEmailRequest {
	email!: string
}

export class CreateSessionRequest {
  email!: string
  password!: string
  reCaptchaToken!: string
	reCaptchaV2!: boolean
}

export class ResetPasswordRequest {
	email!: string
	password!: string
	passwordConfirmation!: string
	resetPasswordCode!: string
  reCaptchaToken!: string
	reCaptchaV2!: boolean
}

export class Preferences {
	mailMonitorUp!: boolean
	mailMonitorDegraded!: boolean
	mailMonitorDisrupted!: boolean
	mailMonitorDown!: boolean
	mailMonitorMaintenance!: boolean
	mailMonitorPaused!: boolean
	mailMonitorCreated!: boolean
	mailMonitorDeleted!: boolean
	mailInvitationAccepted!: boolean
	mailInvitationDeclined!: boolean
	mailWebhookFailing!: boolean
	displayStatusImage!: boolean
	updatedAt!: string
}

export class BillingInformationAddress {
	city!: string;
	country!: string;
	line1!: string;
	line2!: string;
	postalCode!: string;
	state!: string;
}

export class BillingInformationTaxId {
	type!: string;
	value!: string;
	status!: string;
}

export class BillingInformation {
  name!: string;
	email!: string;
	address?: BillingInformationAddress;
	taxIds!: BillingInformationTaxId[];
}

export type { Message } from "./Message";
export { MonitorEventWrap } from "./MonitorEventWrap";
export { MonitorsFilterModel } from "./MonitorsFilterModel";

export class Invoice {
  uuid!: string;
  number!: string;
  stripeId!: string;
  subTotal!: number;
  tax!: number;
  total!: number;
  paymentType!: string;
  paymentDescription!: string;
  invoiceItems!: InvoiceItem[];
  createdAt!: string;
  updatedAt!: string;
  startAt!: string;
  endAt!: string;
  stripeUrl!: string;
	status!: string;
}

export class InvoiceItem {
  invoiceId!: string;
  description!: string;
  product!: Product;
  unitType!: string;
  quantity!: number;
  unitPrice!: number;
  total!: number;
  createdAt!: string;
  updatedAt!: string;
  startsAt!: string;
  endsAt!: string;
}

export class Alert {
  uuid!: string;
  name!: string;
  up!: boolean;
  degraded!: boolean;
  disrupted!: boolean;
  down!: boolean;
  maintenance!: boolean;
  recipients!: AlertRecipient[];
  monitors!: Monitor[];
  monitorsCount!: number;
}

export class AlertRecipient {
  uuid!: string;
  type!: string;
  target?: string;
  createdAt!: string;
  updatedAt!: string;
}

export class CreateAlertReq {
  name!: string;
  up!: boolean;
  degraded!: boolean;
  disrupted!: boolean;
  down!: boolean;
  maintenance!: boolean;
  recipients!: AlertRecipient[];
}

export class UpdateAlertReq {
  name!: string;
  up!: boolean;
  degraded!: boolean;
  disrupted!: boolean;
  down!: boolean;
  maintenance!: boolean;
  recipients!: AlertRecipient[];
}

export class PhoneAlertLog {
  uuid!: string;
  description!: string;
  quantity!: number;
  balance!: number;
  isCredit!: boolean;
  recipients!: string[];
  createdAt!: string;
  invoice!: Invoice;
}

export class GetPhoneAlertCreditsRes {
  logs!: PhoneAlertLog[];
  logsPaginator!: Paginator;
  balance!: number;
}

export class CreateCheckoutSessionForPaymentReq {
  productPriceId!: number;
}

export class CheckoutCompleted {
  checkoutKey!: string
  nextUrlPath!: string
}

export class HttpStatus {
  code!: number
  name!: string
}

export const isMonitorRegionEvent = (o: MonitorRegionEvent | MonitorEvent): o is MonitorRegionEvent => {
  return (o as MonitorRegionEvent).monitorRegionId !== undefined;
}

export const isMonitorEvent = (o: MonitorRegionEvent | MonitorEvent): o is MonitorEvent => {
  return (o as MonitorEvent).monitor !== undefined;
}