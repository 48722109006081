import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { Monitor, MonitorHttpRequest, UpdateMonitorReq } from '../model';
import axios from 'axios';
import { Loading, Modal } from '../ui';
import { Validations } from '../validator';
import { isFieldStringEmpty, isFieldDomainNotMatching } from '../Validations';
import { apiBaseUrl } from "../Consts";
import { MonitorForm } from '../monitor-form';
import { Errors } from '../validator/Validator';
import { useTranslation } from 'react-i18next';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { messageState, productsState, teamState } from '../atoms';
import { useHandleRequestError } from '../hooks';
import { useHistory } from 'react-router-dom';
import { isMonitorIntervalFree } from '../Helper';

type EditMonitorProps = {
  monitorUuid?: string,
  onUpdate: (monitor: Monitor) => void
  onCancel: () => void
  onFetch?: (monitor: Monitor) => void
}

const v = new Validations(new UpdateMonitorReq());
v.addField("name", (v: any) => isFieldStringEmpty(v));
v.addField("urn", (v: any) => isFieldStringEmpty(v) || isFieldDomainNotMatching(v));

export const MonitorEdit: React.FC<EditMonitorProps> = (props) => {

  const { t } = useTranslation();
  const { handleRequestError, handleRequestErrorAvoidingPopup } = useHandleRequestError()
  const team = useRecoilValue(teamState);
  const setMessage = useSetRecoilState(messageState);
  const products = useRecoilValue(productsState);

  const history = useHistory();
  const [ monitor, setMonitor ] = useState<Monitor>();
  const monitorUuid = useMemo(() => props.monitorUuid, [props.monitorUuid])
  const onFetch = useMemo(() => props.onFetch, [props.onFetch])
  const onParentCancel = useMemo(() => props.onCancel, [props.onCancel])
  const [ errorsOnServer, setErrorsOnServer ] = useState<Errors<Monitor>>()
  const [ isSaving, setIsSaving ] = useState(false);

  const fetch = useCallback(() => {
    if (team && monitorUuid) {
      axios.get(`${apiBaseUrl}/${team.uuid}/monitors/${monitorUuid}`)
        .then(response => {
          const monitor = response.data.monitor as Monitor;
          if (monitor.httpRequest && (!monitor.httpRequest.headers || monitor.httpRequest.headers.length == 0)) {
            monitor.httpRequest = {...monitor.httpRequest || {}, headers: [{}]} as MonitorHttpRequest;
          }
          setMonitor(monitor);
          if (onFetch) {
            onFetch(monitor);
          }
        })
        .catch(err => {
          onParentCancel();
          handleRequestError(err);
        });
    }
  }, [team, monitorUuid, onFetch, handleRequestError, onParentCancel]);


  const onSave = useCallback((monitor: Monitor) => {
    if (team && products) {
      const req = new UpdateMonitorReq();
      req.groups = (monitor.groups || []).map(g => g.uuid);
      req.alerts = (monitor.alerts || []).map(a => a.uuid);
      req.regions = monitor.regions.map(r => r.id);
      req.type = monitor.type;
      req.urn = monitor.urn;
      req.intervalId = monitor.intervalId;
      req.name = monitor.name;
      req.title = monitor.title;
      req.description = monitor.description;
      req.timeout = monitor.timeout;
      req.degradedThreshold = typeof monitor.degradedThreshold === "string" ? 0 : monitor.degradedThreshold;
      req.apdexThreshold = typeof monitor.apdexThreshold === "string" ? 0 : monitor.apdexThreshold;
      req.httpFollowRedirects = monitor.httpFollowRedirects
      req.tcpOptions = monitor.tcpOptions;
      req.httpRequest = monitor.httpRequest;
      req.httpResponse = monitor.httpResponse;
      req.maintenance = monitor.maintenance;

      if ((req.type !== "http" && req.type !== "https") || isMonitorIntervalFree(products, monitor.intervalId)) {
        req.httpRequest = undefined;
        req.httpResponse = undefined;
      }

      if (req.type !== "tcp" || isMonitorIntervalFree(products, monitor.intervalId)) {
        req.tcpOptions = undefined;
      }

      if (req.httpRequest && req.httpRequest.body?.length > 0 && req.httpRequest.method === "HEAD") {
        req.httpRequest.body = "";
      }
      if (req.httpResponse) {
        if (req.httpResponse.statusMatches === undefined || req.httpResponse.statusMatches === null) {
          req.httpResponse.statusMatches = false;
        }
        if (req.httpResponse.keywordBodyExists === undefined || req.httpResponse.keywordBodyExists === null) {
          req.httpResponse.keywordBodyExists = false;
        }
        if (req.httpResponse.keywordHeaderExists === undefined || req.httpResponse.keywordHeaderExists === null) {
          req.httpResponse.keywordHeaderExists = false;
        }
      }
      if (req.tcpOptions) {
        if (req.tcpOptions.keywordResponseExists === undefined || req.tcpOptions.keywordResponseExists === null) {
          req.tcpOptions.keywordResponseExists = false;
        }
      }

      setIsSaving(true);
      axios.patch(`${apiBaseUrl}/${team.uuid}/monitors/${monitor.uuid}`, req)
        .then(response =>
          {
            if (response.data.checkoutUrl) {
              window.location.href = response.data.checkoutUrl;
              return
            } else if (props.onUpdate) {
              props.onUpdate(response.data.monitor as Monitor);
            } else {
              history.push(`/${team.slug}/monitors/${(response.data.monitor as Monitor).uuid}`);
            }
            if (response.data.checkoutError) {
              setMessage({
                type: "error",
                text: response.data.checkoutError
              })
            }
          }
        )
        .catch(err => {
          handleRequestErrorAvoidingPopup(err);
          setErrorsOnServer(err.response.data);
        })
        .finally(() => setIsSaving(false));
    }
  }, [team, products, props, handleRequestErrorAvoidingPopup]);

  const onCancel = useCallback(() => {
    props.onCancel();
  }, [props])

  useEffect(() => fetch(), [fetch])

  if (monitorUuid !== undefined) {
    return (
      <Modal
        open={true}
        onClose={ () => props.onCancel() }
        title={t("Edit Monitor")}
        description={ monitor?.name }
        noPadding={true}>
        <div className="p-h-1 p-h-2-M m-v-1 m-v-1.5-M">
          { !monitor ?
            <Loading />
          :
            <MonitorForm
              onSave={onSave}
              isSaving={isSaving}
              onCancel={onCancel}
              monitor={monitor}
              errorsOnServer={errorsOnServer} />
          }
        </div>
      </Modal>
    );
  } else {
    return null;
  }
}
