import React, { useEffect, useMemo, useState } from "react";
import { duration, statusToColor, statusToHumanText } from "../Helper";
import { MonitorStatusType } from "../Consts";
import { CSSTransition } from "react-transition-group";
import { formatResponseTime } from "../Helper";
import { DateTime } from "luxon";
import { useInterval } from "../hooks/UseInterval";
import { MonitorSelector } from "./MonitorSelector";

type MonitorStatusOnProps = {
  monitorSelector: MonitorSelector
}

export const MonitorStatus: React.FC<MonitorStatusOnProps> = (props) => {

  // NodeRef is required in CSSTransition to fix the following error:
  // https://github.com/reactjs/react-transition-group/issues/668
  const nodeStatusDescriptionRef = React.useRef(null);
  const nodeStatusDurationRef = React.useRef(null);
  const nodeRtDescriptionRef = React.useRef(null);

  const monitorSelector = useMemo(() => props.monitorSelector, [props.monitorSelector]);
  const monitor = useMemo(() => props.monitorSelector.monitor, [props.monitorSelector.monitor]);
  const [statusDescription, setStatusDescription] = useState<string>();
  const [statusDuration, setStatusDuration] = useState<string>();
  const [rtDescription, setRtDescription] = useState<string>();
  const color = statusToColor(monitor.status);

  const [blockedTime, setBlockedTime] = useState<number>(
    Math.abs(DateTime.fromISO(monitorSelector.monitor.statusSince).diffNow().milliseconds));
  const [blockedDescription, setBlockedDescription] = useState<string>();


  const [forceUpdate, setForceUpdate] = useState(false);

  useInterval(() => setForceUpdate(val => !val), 10000);

  const [newStatusDescription, setNewStatusDescription] = useState<string>();
  const [newStatusDuration, setNewStatusDuration] = useState<string>();
  const [newRtDescription, setNewRtDescription] = useState<string>();

  useEffect(() => {
    let statusDescription: string | undefined = undefined;
    let statusDuration: string | undefined = undefined;

    if (monitor?.urlStatusPending) {
      statusDescription = "Updating";
      statusDuration = undefined;

    } else if (monitor?.status) {
      statusDescription = statusToHumanText(monitor.status);

      switch (monitor.status) {
        case MonitorStatusType.Up:
        case MonitorStatusType.Degraded:
        case MonitorStatusType.Disrupted:
        case MonitorStatusType.Maintenance:
        case MonitorStatusType.Paused:
        case MonitorStatusType.Down:
          statusDuration = duration(DateTime.fromISO(monitor.statusSince), DateTime.now());
          break;
        case MonitorStatusType.Starting:
        case MonitorStatusType.RequiresPaymentMethod:
      }
    }

    setNewStatusDescription(statusDescription);
    setNewStatusDuration(statusDuration);
  }, [monitor.statusSince, monitor.status, forceUpdate, monitor.urlStatusPending]);


  useEffect(() => {
    let rt: string | undefined = undefined;

    if (monitor) {
      const _responseTime = formatResponseTime(monitor.rt.reduce((t,v) => t+v));

      if (!monitor.urlStatusPending) {
        switch (monitor.status) {
          case MonitorStatusType.Up:
          case MonitorStatusType.Degraded:
            rt = `RT ${_responseTime}`;
            break;
        }
      }
    }

    setNewRtDescription(rt);
  }, [monitor, monitor.rt]);

  useEffect(() => {
    if (newStatusDescription !== statusDescription || newStatusDuration !== statusDuration || newRtDescription !== rtDescription) {
      const timeout = setTimeout(() =>  {
        setStatusDescription(newStatusDescription);
        setStatusDuration(newStatusDuration);
        setRtDescription(newRtDescription);
      }, 500);

      return () => {
        clearTimeout(timeout);
      }
    }
  }, [newRtDescription, newStatusDescription, newStatusDuration, rtDescription, statusDescription, statusDuration]);

  useEffect(() => {

    if (monitorSelector.isStarting) {
      if (monitorSelector.blocked) {
        if (blockedTime < 6000) {
          setBlockedDescription("Pending...");
        } else if (blockedTime < 12000) {
          setBlockedDescription("The monitor is being deployed in the workers...");
        } else {
          setBlockedDescription("The url will be verified very soon. Please wait...");
        }
        const timeout = setTimeout(() => setBlockedTime(t => t + (blockedTime < 10000 ? 2000 : 10)), blockedTime < 10000 ? 2000 : 10);
        return () => {
          if (timeout) {
            clearTimeout(timeout);
          }
        }
      } else {
        setBlockedDescription(undefined);
        setBlockedTime(0);
      }
    } else {
      setBlockedDescription(undefined);
      setBlockedTime(0);
    }
  }, [blockedTime, monitorSelector])

  if (!monitor) {
    return null;
  }

  return (
    monitorSelector.blocked ?
      <div className="d-f fb-d-c">
        <div>{blockedDescription}</div>
        {blockedTime >= 12000 && monitorSelector.isStarting ?
          <div className="h-1 w-100% b-r-0.5 m-t-0.5 bg-c-lightestGray m-r-0.5">
            <div className="h-1 bg-c-g b-r-0.5 max-w-100%" style={{opacity: "0.5", width: `${((blockedTime - 10000) / 20000) * 100}%`}}>
            </div>
          </div>
        : null}
      </div>
    :
      <div className="d-f fb-d-c">
        <div className="d-f" style={{color: color}}>
          <CSSTransition
            nodeRef={nodeStatusDescriptionRef}
            unmountOnExit
            classNames="fade"
            timeout={500}
            in={newStatusDescription === statusDescription}>
            <div ref={nodeStatusDescriptionRef}>
              {statusDescription}
            </div>
          </CSSTransition>
          <CSSTransition
            nodeRef={nodeStatusDurationRef}
            unmountOnExit
            classNames="fade"
            timeout={500}
            in={newStatusDuration === statusDuration}>
            <div ref={nodeStatusDurationRef} className="m-l-0.5">
              {statusDuration}
            </div>
          </CSSTransition>
        </div>
        <CSSTransition
          nodeRef={nodeRtDescriptionRef}
          unmountOnExit
          classNames="fade"
          timeout={500}
          in={newRtDescription === rtDescription}>
          <div className="m-t-0.25" style={{color: color}} ref={nodeRtDescriptionRef}>
            {rtDescription}
          </div>
        </CSSTransition>
      </div>
  );
}