import React, { useCallback, useState, useEffect } from 'react';
import axios from 'axios';
import {
  TextInput,
  Button,
  BaseInputOnChangeEvent,
  ComboBox,
  ComboBoxItem,
  BaseInputOnSelectEvent,
  Spinner, 
  Loading} from '../ui';
import { Country, TimeZone, UpdateSessionUserReq, User } from '../model';
import { useValidator, Validations } from '../validator';
import { apiBaseUrl } from "../Consts";
import Fuse from 'fuse.js'
import { useFuse } from '../hooks/UseFuse';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';
import { countriesState, timeZonesState, userState } from '../atoms';
import { useHandleRequestError } from '../hooks';
import { convertCountryToComboBoxItem, convertTimeZoneToComboBoxItem } from '../Helper';

const v = new Validations(new UpdateSessionUserReq());

const fuseTimeZoneOptions: Fuse.IFuseOptions<TimeZone> = {
  keys: ["name", "systemName", "countryCode"]
}

const fuseCountryOptions: Fuse.IFuseOptions<Country> = {
  keys: ["name", "code", "capital"]
}

export const Information: React.FC = () => {

  const { t } = useTranslation();
  const { handleRequestErrorAvoidingPopup } = useHandleRequestError();
  const [ originalUser, setOriginalUser ] = useRecoilState(userState);
  const timeZones = useRecoilValue(timeZonesState);
  const countries = useRecoilValue(countriesState);

  const [ user, setUser ] = useState<UpdateSessionUserReq>(originalUser as UpdateSessionUserReq);
  const { errors, validateField, validateAll, resetErrorAll, resetErrorAllFromServer } = useValidator(v);
  const [ countryItems, setCountryItems ] = useState<ComboBoxItem[]>();
  const [ timeZoneItems, setTimeZoneItems ] = useState<ComboBoxItem[]>();
  const [ isSaving, setIsSaving ] = useState(false);
  const [ dirty, setDirty ] = useState(false);

  const [ timeZoneInputValue, setTimeZoneInputValue ] = useState<string>();
  const [ countryInputValue, setCountryInputValue ] = useState<string>();

  const [ timeZoneDefaultFocusedIndex, setTimeZoneDefaultFocusedIndex ] = useState<number>(-1);
  const [ countryDefaultFocusedIndex, setCountryDefaultFocusedIndex ] = useState<number>(-1);

  const {
    setTerm: setTermCountries,
    results: resultCountries } = useFuse(countries, "", fuseCountryOptions, 300);

  const {
    setTerm: setTermTimeZones,
    results: resultTimeZones } = useFuse(timeZones, "", fuseTimeZoneOptions, 300);

  const reset = useCallback((user: User) => {
    if (countries) {
      setUser(user as UpdateSessionUserReq);

      setTimeZoneInputValue(timeZones.find(tz => tz.id === user.timeZoneId)?.name);
      setCountryInputValue(countries.find(c => c.id === user.countryId)?.name);
    }
  }, [countries, timeZones]);


  const onSelectCountry = useCallback((event: BaseInputOnSelectEvent) => {
    if (countryItems) {
      const selectedCountry = countryItems[event.index].data as Country;
      if (selectedCountry.id !== user?.countryId) {
        setUser({ ...user, countryId: selectedCountry.id } as UpdateSessionUserReq);
        setDirty(dirty || event.dirty);
      }
      setCountryInputValue(selectedCountry.name);
      setTermCountries("");
    }
    setCountryDefaultFocusedIndex(-1);
  }, [countryItems, setTermCountries, user, dirty]);

  const onSelectTimeZone = useCallback((event: BaseInputOnSelectEvent) => {
    if (timeZoneItems) {
      const selectedTimeZone = timeZoneItems[event.index].data as TimeZone;
      if (selectedTimeZone.id !== user?.timeZoneId) {
        setUser({ ...user, timeZoneId: selectedTimeZone.id } as UpdateSessionUserReq);
        setDirty(dirty || event.dirty);
      }
      setTimeZoneInputValue(selectedTimeZone.name);
      setTermTimeZones("");
    }
    setCountryDefaultFocusedIndex(-1);
  }, [setTermTimeZones, timeZoneItems, user, dirty]);

  const onBlurCountry = useCallback(() => {
    if (countries) {
      const selectedCountry = countries?.find(c => c.id === user?.countryId) as Country;
      setCountryInputValue(selectedCountry.name);
      setTermCountries("");
    }
    setCountryDefaultFocusedIndex(-1);
  }, [countries, setTermCountries, user]);

  const onBlurTimeZone = useCallback(() => {
    if (timeZones) {
      const selectedTimeZone = timeZones?.find(c => c.id === user?.timeZoneId) as TimeZone;
      setTimeZoneInputValue(selectedTimeZone.name);
      setTermTimeZones("");
    }
    setTimeZoneDefaultFocusedIndex(-1);
  }, [setTermTimeZones, timeZones, user]);

  const onChangeField = useCallback(
    (fieldName: keyof UpdateSessionUserReq, event: BaseInputOnChangeEvent) => {
      const value = event.value;
      if (event.dirtyAndTouched) {
        validateField(fieldName, event.value);
      }
      const _user = { ...user } as UpdateSessionUserReq;
      _user[fieldName] = value as never;

      setUser(_user);
      setDirty(dirty || event.dirty);

    }, [dirty, user, validateField])

  const onBlurField = useCallback(
    (fieldName: keyof UpdateSessionUserReq, event: BaseInputOnChangeEvent) => {
      if (event.dirty) {
        validateField(fieldName, event.value);
      }
    }, [validateField])

  const onChangeCountryInputValue = useCallback((e: BaseInputOnChangeEvent) => {
    setCountryInputValue(e.value as string);
    setTermCountries(e.value as string);
    setCountryDefaultFocusedIndex(0);
  }, [setTermCountries]);

  const onChangeTimeZoneInputValue = useCallback((e: BaseInputOnChangeEvent) => {
    setTimeZoneInputValue(e.value as string);
    setTermTimeZones(e.value as string);
    setTimeZoneDefaultFocusedIndex(0);
  }, [setTermTimeZones]);

  const save = useCallback(() => {
    setIsSaving(true);
    if (user && validateAll(user)) {
      axios.patch(`${apiBaseUrl}/session/user`, user)
        .then((response) => {
          const user = response.data.user as User;
          setOriginalUser(user);
          reset(user);
          resetErrorAll();
          setDirty(false);
          setOriginalUser(user);
        })
        .catch(err => {
          handleRequestErrorAvoidingPopup(err);
          resetErrorAllFromServer(err.response.data);
        })
        .finally(() => setIsSaving(false));
    }
  }, [user, validateAll, setOriginalUser, reset, resetErrorAll, handleRequestErrorAvoidingPopup, resetErrorAllFromServer])

  const discard = useCallback(() => {
    reset(originalUser as User);
    resetErrorAll();
    setDirty(false);
  }, [originalUser, reset, resetErrorAll]);

  useEffect(() => {
    if (resultCountries && resultCountries.length > 0) {
      setCountryItems(resultCountries.map((c: any) => convertCountryToComboBoxItem(c.item, user?.countryId)));
    } else if (countries) {
      setCountryItems(countries.map((c: any) => convertCountryToComboBoxItem(c, user?.countryId)));
    }
  }, [countries, resultCountries, user])

  useEffect(() => {
    if (resultTimeZones && resultTimeZones.length > 0) {
      setTimeZoneItems(resultTimeZones.map((tz: any) => convertTimeZoneToComboBoxItem(tz.item, user?.timeZoneId)));
    } else if (timeZones) {
      setTimeZoneItems(timeZones.map((tz: any) => convertTimeZoneToComboBoxItem(tz, user?.timeZoneId)));
    }
  }, [resultTimeZones, timeZones, user])

  useEffect(() => {
    if (originalUser) {
      reset(originalUser);
    }
  }, [originalUser, reset])

  if (!user) {
    return <Loading />
  }
  
  return (
    <form onSubmit={(e) => { e.preventDefault(); save(); } }>
      <div className="f-w-300 f-s-1.5 f-s-2-M m-t-1.5">{t("My Profile")}</div>
      <div className="t-c-b f-w-b f-s-1.25 m-t-3">{t("Profile Information")}</div>
      <div className="d-f fb-d-c fb-d-r-M m-t-1 m-t-2-M">
        <div className="w-50%-M d-f fb-d-c p-r-1-M">
          <TextInput
            autoFocus={true}
            label={t("First Name")}
            value={user.firstName}
            placeholder={t("Enter First Name")}
            onChange={e => onChangeField("firstName", e)}
            onBlur={e => onBlurField("firstName", e)}
            error={errors.fields.firstName}
            maxLength={25}
            type="text" />
        </div>
        <div className="w-50%-M d-f fb-d-c p-l-1-M m-t-1 m-t-0-M">
          <TextInput
            label={t("Last Name")}
            value={user.lastName}
            placeholder={t("Enter Last Name")}
            onChange={e => onChangeField("lastName", e)}
            onBlur={e => onBlurField("lastName", e)}
            error={errors.fields.firstName}
            maxLength={25}
            type="text" />
        </div>
      </div>
      <div className="d-f fb-d-c m-t-1 m-t-2-M">
        <ComboBox
          value={timeZoneInputValue}
          defaultFocusedIndex={timeZoneDefaultFocusedIndex}
          onSelect={onSelectTimeZone}
          onBlur={onBlurTimeZone}
          onInputValueChange={onChangeTimeZoneInputValue}
          label={t("Timezone")}
          options={ timeZoneItems } />
      </div>
      <div className="d-f fb-d-c m-t-1 m-t-2-M">
        <ComboBox
          value={countryInputValue}
          defaultFocusedIndex={countryDefaultFocusedIndex}
          onSelect={onSelectCountry}
          onBlur={onBlurCountry}
          onInputValueChange={onChangeCountryInputValue}
          label={t("Country")}
          options={ countryItems } />
      </div>
      {dirty ?
        <div className="d-f j-c-e p-v-1 p-v-2-M b-sb-s b-c-lightestGray b-w-1px">
          {isSaving ?
            <Spinner type={"primary"} />
          :
            <div className="d-f fb-d-cr fb-d-r-M w-100% m-v-1 j-c-e a-i-c">
              <div onClick={discard} className="t-c-g f-s-1.25 c-p p-t-1 p-t-0-M">
                {t("Cancel")}
              </div>
              <div className="m-l-1-M w-100% w-auto-M">
                <Button submit={true} type="primary" fullWidth="mobile">
                  {t("Save")}
                </Button>
              </div>
            </div>
          }
        </div>
      : null }
    </form>
  )
}