import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Monitor, MonitorMaintenance, MonitorMaintenanceTime, TimeZone, UpdateSessionUserReq } from "../model";
import { BaseInputOnChangeEvent, BaseInputOnSelectEvent, ComboBox, ComboBoxItem, HelpTip, SelectInput, SelectInputItem  } from "../ui";
import { SelectionButton } from "../ui/selection-button/SelectionButton";
import { Errors } from "../validator/Validator";
import { convertTimeZoneSystemNameToComboBoxItem, convertTimeZoneToComboBoxItem, getWeekDay, hourAndMinuteIsBeforeThan } from "../Helper";
import { X } from "../images";
import Fuse from 'fuse.js'
import { useFuse } from "../hooks/UseFuse";
import { useRecoilState, useRecoilValue } from "recoil";
import { timeZonesState, userState } from "../atoms";
import { MonitorMaintenanceTimeOnNextDay } from "./MonitorForm";

type MaintenanceProps = {
  monitor: Monitor
  onChangeMaintenaceDaysField: (days: number[]) => void
  onChangeMaintenaceTimeZoneField: (timeZone: string) => void

  onChangeMaintenanceTimeField: (index: number, fieldName: keyof MonitorMaintenanceTime, event: BaseInputOnChangeEvent) => void
  // onBlutMaintenanceTimeField: (index: number, fieldName: keyof MonitorMaintenanceTime, event: BaseInputOnBlurEvent) => void
  onAddMaintenanceTime: () => void
  onRemoveMaintenanceTime: (index: number) => void
  errorsMaintenace: Errors<MonitorMaintenance>
  errorsMaintenanceTime: { [name: string]: string | undefined }[]
  timeOnNextDay?: MonitorMaintenanceTimeOnNextDay
}

export const Maintenance: React.FC<MaintenanceProps> = (props) => {

  const { t } = useTranslation();

  const timeZones = useRecoilValue(timeZonesState);

  const fuseTimeZoneOptions: Fuse.IFuseOptions<TimeZone> = {
    keys: ["name", "systemName", "countryCode"]
  }

  const maintenance = props.monitor.maintenance;

  const [ timeZoneItems, setTimeZoneItems ] = useState<ComboBoxItem[]>();

  const [ timeZoneInputValue, setTimeZoneInputValue ] = useState<string>();

  const [ timeZoneDefaultFocusedIndex, setTimeZoneDefaultFocusedIndex ] = useState<number>(-1);

  const {
    setTerm: setTermTimeZones,
    results: resultTimeZones } = useFuse(timeZones, "", fuseTimeZoneOptions, 300);

  const onChangeTimeZoneInputValue = useCallback((e: BaseInputOnChangeEvent) => {
    setTimeZoneInputValue(e.value as string);
    setTermTimeZones(e.value as string);
    setTimeZoneDefaultFocusedIndex(0);
  }, [setTermTimeZones]);

  const onSelectTimeZone = useCallback((event: BaseInputOnSelectEvent) => {
    if (timeZoneItems && maintenance) {
      const selectedTimeZone = timeZoneItems[event.index].data as TimeZone;
      if (selectedTimeZone.systemName !== maintenance.timeZone) {
        props.onChangeMaintenaceTimeZoneField(selectedTimeZone.systemName);
      }
      setTimeZoneInputValue(selectedTimeZone.name);
      setTermTimeZones("");
    }
  }, [setTermTimeZones, timeZoneItems, maintenance]);

  const onBlurTimeZone = useCallback(() => {
    if (timeZones && maintenance) {
      const selectedTimeZone = timeZones?.find(tz => tz.systemName === maintenance.timeZone) as TimeZone;
      setTimeZoneInputValue(selectedTimeZone.name);
      setTermTimeZones("");
    }
    setTimeZoneDefaultFocusedIndex(-1);
  }, [setTermTimeZones, timeZones, maintenance]);


  const disabledAddButton = useMemo(() =>
    maintenance?.times[maintenance?.times.length-1].stopMinute === undefined
  , [maintenance]);

  useEffect(() => {
    if (resultTimeZones && resultTimeZones.length > 0 && maintenance) {
      setTimeZoneItems(resultTimeZones.map((tz: any) => convertTimeZoneSystemNameToComboBoxItem(tz.item, maintenance.timeZone)));
    } else if (timeZones && maintenance) {
      setTimeZoneItems(timeZones.map((tz: TimeZone) => convertTimeZoneSystemNameToComboBoxItem(tz, maintenance.timeZone)));
    }
  }, [resultTimeZones, timeZones, maintenance])


  useEffect(() => {
    if (maintenance) {
      setTimeZoneInputValue(timeZones.find(tz => tz.systemName === maintenance.timeZone)?.name);
    }
  }, [maintenance]);


  return <div className="d-f fb-d-c m-t-2">
    <div className="d-f a-i-c p-b-0.5">
      <div className="f-s-1.25 p-r-0.75">
        {t("Maintenance Window")}
      </div>
      <HelpTip>
        <div className="max-w-20">
          <Trans>
            Maintenance windows let you specify times when &quot;down&quot; and &quot;disrupted&quot; alerts from monitors are ignored. You can set multiple windows, which might extend into the next day based on the hours specified.<br /><br /><b>Be aware</b> that if a monitor is added or modified, the system immediately checks and reports the target URL&#39;s status, even if it&#39;s within a maintenance window.
          </Trans>
        </div>
      </HelpTip>


      

    </div>
    <div className="d-f fb-d-c a-i-s b-s-s-M b-w-1px-M b-c-lightestGray-M p-h-2-M p-b-2-M p-t-1.5-M">
      <div className="d-f fb-w-w">
        {[0, 1, 2, 3, 4, 5, 6].map(i =>
          <div key={i} className="d-f m-r-0.5 m-r-1-M m-r-0:lot p-t-0.5">
            <SelectionButton
              onChange={e => {
                props.onChangeMaintenaceDaysField(
                  e.value ?
                    [...(maintenance?.days ?? []), i] :
                      (maintenance?.days ?? []).filter(d => d !== i))
              }}
              checked={maintenance?.days.includes(i)}
              label={getWeekDay(i)} />
          </div>
        )}
      </div>
      {maintenance && maintenance.days.length > 0 ?
        <>
          <div className="d-f fb-d-c w-100% m-t-1.5">
            <div className="w-50% p-r-0.5 p-r-1.5-L">
              {t("Timezone")}
            </div>
            <ComboBox
              value={timeZoneInputValue}
              defaultFocusedIndex={timeZoneDefaultFocusedIndex}
              onSelect={onSelectTimeZone}
              onBlur={onBlurTimeZone}
              onInputValueChange={onChangeTimeZoneInputValue}
              placeholder={t("Select Timezone")}
              options={ timeZoneItems } />
          </div>
          <div className={`d-f w-100% m-t-1.5 ${maintenance.times.length > 1 ? "p-r-2 p-r-2.5-L" : "" }`}>
            <div className="w-50% p-r-0.5 p-r-1.5-L">
              {t("Start")}
            </div>
            <div className="w-50% p-l-0.5 p-l-1.5-L">
              {t("Stop")}
            </div>
          </div>
          {maintenance.times.map((time, i) =>
            <MaintenanceTime
              key={i}
              time={time}
              countTimes={maintenance.times.length}
              index={i}
              onChangeField={props.onChangeMaintenanceTimeField}
              onRemove={props.onRemoveMaintenanceTime}
              timeOnNextDay={props.timeOnNextDay}
              errors={props.errorsMaintenanceTime[i]}
            />
          )}
          <div className="d-f m-t-1 j-c-e j-c-s">
            <div
              onClick={disabledAddButton ? undefined : props.onAddMaintenanceTime}
              className={`f-s-1 f-s-1.25-M ${disabledAddButton ? "t-c-gray c-na" : "t-c-g c-p"}`}>
              + {t("Add")}
            </div>
          </div>
        </>
      : null}
    </div>
  </div>
}

type MaintenanceTimeProps = {
  index: number
  time: MonitorMaintenanceTime
  countTimes: number
  nextDayIndex?: number
  onChangeField: (index: number, fieldName: keyof MonitorMaintenanceTime, event: BaseInputOnChangeEvent) => void
  onRemove: (index: number) => void
  timeOnNextDay?: MonitorMaintenanceTimeOnNextDay
  errors: { [name: string]: string | undefined }
}


const MaintenanceTime: React.FC<MaintenanceTimeProps> = (props) => {

  const { t } = useTranslation();

  return <>
      {props.timeOnNextDay?.index === props.index ?
        <div className={`w-100% d-f ${props.index > 0 ? "m-t-0.75" : "m-t-0.5"} ${props.countTimes > 1 ? "p-r-1.5 p-r-2.5-L" : ""}`}>
          <div className={`w-50% p-r-0.5 p-r-1.5-L f-s-0.8`}>
            {!props.timeOnNextDay.isStop ? t("Next Day") : null}
          </div>
          <div className={`w-50% p-l-0.5 p-l-1.5-L f-s-0.8`}>
            {props.timeOnNextDay.isStop ? t("Next Day") : null}
          </div>
        </div>
      : null}
      <div className={`d-f w-100% ${props.index > 0 || props.timeOnNextDay?.index === props.index ? "m-t-0.25" : "m-t-1"} a-i-t`}>
        <div className="fb-a p-r-0.5 p-r-1.5-L d-f fb-w-w w-50%">
          <div className="w-50% p-r-0.25 p-r-0.5-L">
            <SelectInput
              options={hourOptions}
              onSelect={(e) => props.onChangeField(props.index, "startHour", new BaseInputOnChangeEvent(e.index, e.dirty, e.touched))}
              indexSelected={props.time.startHour}
              placeholder={t("Hour")}
              error={props.errors?.startHour}
            />
          </div>
          <div className="w-50% p-l-0.25 p-l-0.5-L">
            <SelectInput
              options={minuteOptions}
              onSelect={(e) => props.onChangeField(props.index, "startMinute", new BaseInputOnChangeEvent(e.index, e.dirty, e.touched))}
              disabled={props.time.startHour === undefined}
              indexSelected={props.time.startMinute}
              placeholder={t(`Minute`)}
              error={props.errors?.startMinute}
            />
          </div>
        </div>
        <div className="fb-a p-l-0.5 p-l-1.5-L d-f fb-w-w a-i-s j-c-s w-50%">
          <div className="w-50% p-r-0.25 p-r-0.5-L">
            <SelectInput
              options={hourOptions}
              onSelect={(e) => props.onChangeField(props.index, "stopHour", new BaseInputOnChangeEvent(e.index, e.dirty, e.touched))}
              disabled={props.time.startMinute === undefined}
              indexSelected={props.time.stopHour}
              placeholder={t(`Hour`)}
              error={props.errors?.stopHour}
            />
          </div>
          <div className="w-50% p-l-0.25 p-l-0.5-L">
            <SelectInput
              options={minuteOptions}
              onSelect={(e) => props.onChangeField(props.index, "stopMinute", new BaseInputOnChangeEvent(e.index, e.dirty, e.touched))}
              disabled={props.time.stopHour === undefined}
              indexSelected={props.time.stopMinute}
              placeholder={t(`Minute`)}
              error={props.errors?.stopMinute}
            />
          </div>
        </div>
        {props.countTimes > 1 ?
          <div  className="fb-n t-c-g p-l-0.5 p-l-1-L w-2 w-2.5-L p-t-0.75">
            <div className="c-p" onClick={() => props.onRemove(props.index)}><X /></div>
          </div>
        : null}
      </div>
    </>

}

const hourOptions: SelectInputItem[] = [
  new SelectInputItem("00", 0),
  new SelectInputItem("01", 1),
  new SelectInputItem("02", 2),
  new SelectInputItem("03", 3),
  new SelectInputItem("04", 4),
  new SelectInputItem("05", 5),
  new SelectInputItem("06", 6),
  new SelectInputItem("07", 7),
  new SelectInputItem("08", 8),
  new SelectInputItem("09", 9),
  new SelectInputItem("10", 10),
  new SelectInputItem("11", 11),
  new SelectInputItem("12", 12),
  new SelectInputItem("13", 13),
  new SelectInputItem("14", 14),
  new SelectInputItem("15", 15),
  new SelectInputItem("16", 16),
  new SelectInputItem("17", 17),
  new SelectInputItem("18", 18),
  new SelectInputItem("19", 19),
  new SelectInputItem("20", 20),
  new SelectInputItem("21", 21),
  new SelectInputItem("22", 22),
  new SelectInputItem("23", 23)
];

const minuteOptions: SelectInputItem[] = [
  new SelectInputItem("00", 0),
  new SelectInputItem("01", 1),
  new SelectInputItem("02", 2),
  new SelectInputItem("03", 3),
  new SelectInputItem("04", 4),
  new SelectInputItem("05", 5),
  new SelectInputItem("06", 6),
  new SelectInputItem("07", 7),
  new SelectInputItem("08", 8),
  new SelectInputItem("09", 9),
  new SelectInputItem("10", 10),
  new SelectInputItem("11", 11),
  new SelectInputItem("12", 12),
  new SelectInputItem("13", 13),
  new SelectInputItem("14", 14),
  new SelectInputItem("15", 15),
  new SelectInputItem("16", 16),
  new SelectInputItem("17", 17),
  new SelectInputItem("18", 18),
  new SelectInputItem("19", 19),
  new SelectInputItem("20", 20),
  new SelectInputItem("21", 21),
  new SelectInputItem("22", 22),
  new SelectInputItem("23", 23),
  new SelectInputItem("24", 24),
  new SelectInputItem("25", 25),
  new SelectInputItem("26", 26),
  new SelectInputItem("27", 27),
  new SelectInputItem("28", 28),
  new SelectInputItem("29", 29),
  new SelectInputItem("30", 30),
  new SelectInputItem("31", 31),
  new SelectInputItem("32", 32),
  new SelectInputItem("33", 33),
  new SelectInputItem("34", 34),
  new SelectInputItem("35", 35),
  new SelectInputItem("36", 36),
  new SelectInputItem("37", 37),
  new SelectInputItem("38", 38),
  new SelectInputItem("39", 39),
  new SelectInputItem("40", 40),
  new SelectInputItem("41", 41),
  new SelectInputItem("42", 42),
  new SelectInputItem("43", 43),
  new SelectInputItem("44", 44),
  new SelectInputItem("45", 45),
  new SelectInputItem("46", 46),
  new SelectInputItem("47", 47),
  new SelectInputItem("48", 48),
  new SelectInputItem("49", 49),
  new SelectInputItem("50", 50),
  new SelectInputItem("51", 51),
  new SelectInputItem("52", 52),
  new SelectInputItem("53", 53),
  new SelectInputItem("54", 54),
  new SelectInputItem("55", 55),
  new SelectInputItem("56", 56),
  new SelectInputItem("57", 57),
  new SelectInputItem("58", 58),
  new SelectInputItem("59", 59)
];