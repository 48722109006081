import { DateTime } from 'luxon';
import React from 'react';
import { MonitorStatusType, SelectorStatusType, StatusColors, StatusType } from "./Consts";
import { AverageRt, Country, Product, TimeZone } from './model';
import { ComboBoxItem, ComboBoxItemType } from './ui';

export const isMonitorIntervalFree = (products: Product[], intervalId: number) =>
  products.filter(p => p.productType === "monitor").some(p =>
    p.monitorInterval.id === intervalId && (!p.prices || p.prices.length === 0)
  );

export const statusToColor = (status: StatusType) => {
  switch (status) {
    case MonitorStatusType.Starting:
    case MonitorStatusType.Up:
      return StatusColors.Up;
    case MonitorStatusType.Degraded:
      return StatusColors.Degraded;
    case MonitorStatusType.Disrupted:
      return StatusColors.Disrupted;
    case MonitorStatusType.Down:
      return StatusColors.Down;
    case SelectorStatusType.Maintaining:
    case MonitorStatusType.Maintenance:
      return StatusColors.Maintenance;
    case SelectorStatusType.Pausing:
    case SelectorStatusType.Deleting:
    case MonitorStatusType.Paused:
    default:
      return StatusColors.Paused;
  }
}

export const statusToHumanText = (status: StatusType) => {
  switch (status) {
    case MonitorStatusType.RequiresPaymentMethod:
      return "Requires Payment Method"
    case MonitorStatusType.Starting:
    case MonitorStatusType.Up:
    case MonitorStatusType.Degraded:
    case MonitorStatusType.Disrupted:
    case MonitorStatusType.Maintenance:
    case MonitorStatusType.Paused:
    case MonitorStatusType.Down:
      return `${status.charAt(0).toUpperCase() + status.slice(1)}`;
  }
}

export const hasValidationError = (error: any): boolean => {
  return (typeof error.response.data === "object" && Object.keys(error.response.data).length > 0);
}

export const reduceStatus = (statuses: MonitorStatusType[], upOverStarring: boolean = true): MonitorStatusType => {
	let finalStatus: MonitorStatusType = MonitorStatusType.Paused;
  let statusStarting = false;
	let statusUp = false;
	let statusDegraded = false;
	let statusDisrupted = false;
	let statusMaintenance = false;
  let statusDown = false;
  let statusPaused = false;

	statuses.forEach(status => {
		switch (status) {
      case MonitorStatusType.Starting:
        statusStarting = true;
        break;
      case MonitorStatusType.Up:
        statusUp = true;
        break;
      case MonitorStatusType.Degraded:
        statusDegraded = true;
        break;
      case MonitorStatusType.Disrupted:
        statusDisrupted = true;
        break;
      case MonitorStatusType.Maintenance:
        statusMaintenance = true;
        break;
      case MonitorStatusType.Down:
        statusDown = true;
        break;
      case MonitorStatusType.Paused:
      default:
        statusPaused = true;
        break;
		}
  });

	if (statusUp) {
		finalStatus = MonitorStatusType.Up
	}

	if (statusPaused) {
		finalStatus = MonitorStatusType.Paused
	}

	if (statusStarting) {
		finalStatus = MonitorStatusType.Starting
		if (upOverStarring && statusUp && !statusPaused) {
			finalStatus = MonitorStatusType.Up
		}
	}

	if (statusDegraded) {
		finalStatus = MonitorStatusType.Degraded
	}

	if (statusDisrupted) {
		finalStatus = MonitorStatusType.Disrupted
	}

	if (statusMaintenance) {
		finalStatus = MonitorStatusType.Maintenance
	}

	if (statusDown) {
		finalStatus = MonitorStatusType.Down
		if (statusUp || statusDegraded || statusDisrupted) {
			finalStatus = MonitorStatusType.Disrupted
		}
		if (statusMaintenance) {
			finalStatus = MonitorStatusType.Maintenance
		}
	}

	return finalStatus
}

export const getCheckBoxZise = (zise?: string) => {
  switch (zise) {
    case "mini":
      return 12;
    case "small":
      return 16;
    case "medium":
      return 24;
    case "large":
      return 32
    default:
      return 16;
  }
}

export const getWeekDay = (day: number) => {
  switch (day) {
    case 0:
      return "Sunday";
    case 1:
      return "Monday";
    case 2:
      return "Tuesday";
    case 3:
      return "Wednesday";
    case 4:
      return "Thursday";
    case 5:
      return "Friday";
    case 6:
      return "Saturday";
    default:
      return "Sunday";
  }
}

export const getLastDayMonth = (month: number) => {
  switch (month) {
    case 1:
      return 31;
    case 2:
      return 28;
    case 3:
      return 31;
    case 4:
      return 30;
    case 5:
      return 31
    case 6:
      return 30
    case 7:
      return 31
    case 8:
      return 31
    case 9:
      return 30
    case 10:
      return 31
    case 11:
      return 30
    case 12:
      return 31
    default:
      return 31
  }
}


export const getMonthName = (month: number) => {
  switch (month) {
    case 1:
      return "January";
    case 2:
      return "February";
    case 3:
      return "March";
    case 4:
      return "April";
    case 5:
      return "May";
    case 6:
      return "June";
    case 7:
      return "July";
    case 8:
      return "August";
    case 9:
      return "September";
    case 10:
      return "October";
    case 11:
      return "November";
    case 12:
      return "December"
    default:
      throw Error("Incorrect Month Number " + month);
  }
}

export const renderError = (error: string): React.ReactNode => {
  return <div className="t-c-r f-s-1 m-t-0.5">{ error }</div>
}

export const renderErrors = (error?: string | string[]): React.ReactNode => {
  if (error) {
    if (typeof error === "string") {
      return renderError(error);
    } else {
      return (error as string[]).map(e => renderError(e));
    }
  } else {
    return null;
  }
}

export const formatResponseTime = (responseTime: number): string => {
  return responseTime < 1000 ?
    Math.round(responseTime) + ' ms' :
    (Math.round((responseTime / 1000) * 100) / 100) + ' s';
}

export const formatAmount = (amount: number, decimals: number = 4): string => {
  const factor = Math.pow(10, decimals);
  return `$${(Math.round(amount * factor) / factor).toFixed(decimals)}`;
}

export const camelToDasherizedCase = (camel: string) =>
  camel[0].toLowerCase() + camel.slice(1, camel.length).replace(/[A-Z]/g, letter => `-${letter.toLowerCase()}`);

export const dasherizedToCamelCase = (dasherized: string) =>
  dasherized.charAt(0).toUpperCase() + dasherized.toLowerCase().replace(/-(.)/g, function(match, group1) {
    return group1.toUpperCase();
  }).slice(1);

export const roundMaxChartAxis = (value: number) => {
  const log = Math.ceil(Math.log10(value));
  let factor = Math.pow(10, log);
  if (factor !== value) {
    factor = factor / 10.0;
  }

  let max: number;
  if (factor * 6 <= value) {
    max = Math.pow(10, log);
  } else {
    max = Math.ceil(value/factor) * factor;
  }

  if (max === value) {
    max += factor;
  }

  return max;
}

export const copyElementToClipboard = (element: HTMLElement) => {
  if (window) {
    window.getSelection()?.removeAllRanges();
    const range = document.createRange();
    range.selectNode(element);
    const selection = window.getSelection();
    if (selection) {
      selection.addRange(range);
      document.execCommand('copy');
      selection.removeAllRanges();
    }
  }
}


// Credit to Sindre Sorhus, https://github.com/sindresorhus/escape-string-regexp
export const escapeStringRegexp = (keyword: string) => {
  return keyword
    .replace(/[|\\{}()[\]^$+*?.]/g, '\\$&')
    .replace(/-/g, '\\x2d');
}

export const calculateApdex = (averages: AverageRt[]): number | undefined => {
  
  let apdexCount = 0;
  let apdexTotal = 0;
  let hasThreshold = false;

  averages.forEach(a => {
    if (a.apdexThreshold > 0) {
      hasThreshold = true;
      apdexTotal += (a.apdexSatisfied + (a.apdexTolerated / 2)) / a.apdexSamples;
      apdexCount++;
    }
  })

  if (hasThreshold) {
    return apdexTotal / apdexCount;
  }

  return undefined;
}

export const scrollToInputError = () => {
  const el = document.querySelector('.input-error');
  if (el) {
    el.scrollIntoView({block: "center"});
  }
}

export const convertCountryToComboBoxItem = (c: Country, countryId?: number): ComboBoxItem => {
  return new ComboBoxItem(c.name, c.id === countryId ? ComboBoxItemType.Selected : undefined, c);
}

export const duration = (from: DateTime, to: DateTime): string => {
  const durationObject = to.diff(from, ['years', 'months', 'days', 'hours', 'minutes', 'seconds']);

  if (to.diff(from, ['seconds']).seconds >= 1) {
    let formatDuration = "s's'";

    if (durationObject.minutes >= 1) {
      formatDuration = `m'm' ${formatDuration}`;
    }
    if (durationObject.hours >= 1) {
      formatDuration = `h'h' ${formatDuration}`;
    }
    if (durationObject.days >= 1) {
      formatDuration = `d'd' ${formatDuration}`;
    }
    if (durationObject.months >= 1) {
      formatDuration = `M'M' ${formatDuration}`;
    }
    if (durationObject.years >= 1) {
      formatDuration = `yy'y' ${formatDuration}`;
    }
    return durationObject.toFormat(formatDuration);
  }

  return "";
}

export const capitalize = (text: string): string => {
  if (text) {
    const lower = text.toLowerCase();
    return lower.charAt(0).toUpperCase() + lower.slice(1);
  }
  return text;
}


export const sizeFlag = (size?: string) => {

  switch (size) {
    case "mini":
      return {
        width: "24",
        height: "18"
      };
    case "small":
      return {
        width: "40",
        height: "30"
      };
    case "medium":
      return {
        width: "48",
        height: "36"
      };
    case "big":
      return {
        width: "72",
        height: "54"
      };
    default:
      return {
        width: "24",
        height: "18"
      };
  }
}

export const isElementVisible = function (ele: HTMLElement, container: HTMLElement): boolean {
  const eleTop = ele.offsetTop;
  const eleBottom = eleTop + ele.clientHeight;

  const containerTop = container.offsetTop + container.scrollTop;
  const containerBottom = containerTop + container.clientHeight;

  const eleLeft = ele.offsetLeft;
  const eleRight = eleLeft + ele.clientWidth;

  const containerLeft = container.offsetLeft + container.scrollLeft;
  const containerRight = containerLeft + container.clientWidth;

  // The element is fully visible in the container
  return (
      (eleTop >= containerTop && eleBottom <= containerBottom) &&
      (eleLeft >= containerLeft && eleRight <= containerRight)
  );
};

export const scrollToBeVisible = function (ele: HTMLElement, container: HTMLElement) {
  const eleTop = ele.offsetTop;
  const eleBottom = eleTop + ele.clientHeight;

  const containerTop = container.scrollTop + container.offsetTop;
  const containerBottom = containerTop + container.clientHeight;

  const eleLeft = ele.offsetLeft;
  const eleRight = eleLeft + ele.clientWidth;
  
  const containerLeft = container.scrollLeft + container.offsetLeft;
  const containerRight = containerLeft + container.clientWidth;

  const scrollPositions: ScrollToOptions = {behavior: "smooth"};

  if (eleTop < containerTop) {
      // Scroll to the top of container
      scrollPositions.top = eleTop - (containerTop - container.scrollTop);
  } else if (eleBottom > containerBottom) {
      // Scroll to the bottom of container
      scrollPositions.top = container.scrollTop + eleBottom - containerBottom;
  }

  if (eleLeft < containerLeft) {
    // Scroll to the top of container
    scrollPositions.left = eleLeft - (containerLeft - container.scrollLeft);
  } else if (eleRight > containerRight) {
    // Scroll to the bottom of container
    scrollPositions.left = container.scrollLeft + eleRight - containerRight;
  }

  if (scrollPositions.top !== undefined || scrollPositions.left !== undefined) {
    container.scrollTo(scrollPositions)
  }

};

export const extractNestedServerErrors = (prefix: string, errorsOnServer: {[field: string]: any}) => {

  const regex = new RegExp(`^${prefix}\\.[a-zA-Z]+$`);
  return Object.keys(errorsOnServer as {[field: string]: any})
    .filter(f => f.match(regex))
    .map(f => { const x = f.split(".");  return x[x.length -1] })
    .reduce((errors, key)=>(
      { ...errors,
        [key]: (errorsOnServer as {[field: string]: any})[`${prefix}.${key}`]
      }), {} as {[field: string]: any});
}

export const extractNestedIndexedServerErrors = (prefix: string, errorsOnServer: {[field: string]: any}) => {

  const regex = new RegExp(`^${prefix}\\.[0-9]+\\.[a-zA-Z]+$`);
  return Object.keys(errorsOnServer as {[field: string]: any})
    .filter(f => f.match(regex))
    .map(f => { const x = f.split(".");  return {index: parseInt(x[x.length -2]), field: x[x.length -1]} })
    .reduce((errors, {index, field})=>(
      { ...errors,
        [index]: {...errors[index] || {}, ...{[field]: (errorsOnServer as {[field: string]: any})[`${prefix}.${index}.${field}`]}}
      }), {} as {[index: number]: {[field: string]: any}});
}

export const convertTimeZoneToComboBoxItem = (t: TimeZone, timeZoneId?: number): ComboBoxItem => {
  return new ComboBoxItem(t.name, t.id === timeZoneId ? ComboBoxItemType.Selected : undefined, t);
}

export const convertTimeZoneSystemNameToComboBoxItem = (t: TimeZone, systemName?: string): ComboBoxItem => {
  return new ComboBoxItem(t.name, t.systemName === systemName ? ComboBoxItemType.Selected : undefined, t);
}

export function hourAndMinuteIsBeforeThan(hour: number, minute: number, hourThan: number, minuteThan: number) {
  return hour < hourThan || (hour === hourThan && minute < minuteThan);
}

export function hourAndMinuteIsBeforeOrEqualThan(hour: number, minute: number, hourThan: number, minuteThan: number) {
  return hour < hourThan || (hour === hourThan && minute <= minuteThan);
}
